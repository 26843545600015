import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import './ContactUs.scss'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import useHelpCenterCards from '../HelpCenter/useHelpCenterCardsList'

const CompanyInfo = () => {
  return (
    <address>
      <span>Email: help@getmega.com</span>
      <span>Megashots Internet Private Limited</span>
      <span>25, 8th Main Rd, Vasanth Nagar, Bengaluru</span>
      <span>Karnataka 560052, IN</span>
    </address>
  )
}

const ContactUs: React.FC = () => {
  const [showLinks, setShowLinks] = useState(false)
  const [sent, setSent] = useState(false)

  const helpCenterCards = useHelpCenterCards()

  const data = useStaticQuery(graphql`
    {
      down: file(relativePath: { eq: "down-arrow-circle-blue.png" }) {
        childImageSharp {
          gatsbyImageData(width: 32, layout: CONSTRAINED)
        }
      }
      up: file(relativePath: { eq: "up-arrow-circle-blue.png" }) {
        childImageSharp {
          gatsbyImageData(width: 32, layout: CONSTRAINED)
        }
      }
      fb: file(relativePath: { eq: "fb-blue.png" }) {
        childImageSharp {
          gatsbyImageData(width: 18, layout: CONSTRAINED)
        }
      }
      li: file(relativePath: { eq: "linkedin-blue.png" }) {
        childImageSharp {
          gatsbyImageData(width: 18, layout: CONSTRAINED)
        }
      }
      insta: file(relativePath: { eq: "instagram-blue.png" }) {
        childImageSharp {
          gatsbyImageData(width: 18, layout: CONSTRAINED)
        }
      }
    }
  `)

  const renderSocial = () => {
    return (
      <div className="social">
        <a
          href="http://bit.ly/GM_Insta"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <GatsbyImage
            image={data.insta.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="instagram"
            imgStyle={{ objectFit: 'contain' }}
            className="img"
          />
        </a>
        <a
          href="http://bit.ly/GM-Fb"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <GatsbyImage
            image={data.fb.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="facebook"
            imgStyle={{ objectFit: 'contain' }}
            className="img"
          />
        </a>
        {/* <a
          href="https://www.linkedin.com/company/getmega/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <Img loading="eager"
            fluid={data.li.childImageSharp.fluid}
            alt="linkedin"
            imgStyle={{ objectFit: 'contain' }}
            className="img"
          />
        </a> */}
      </div>
    )
  }

  const initializeState = () => {
    return {
      name: '',
      email: '',
      query: '',
      nameError: '',
      emailError: '',
      queryError: '',
      sending: false,
      apiError: '',
    }
  }

  const [state, setState] = useState(initializeState())

  const validate = (): boolean => {
    let isValidated = true
    let nameError = ''
    let emailError = ''
    let queryError = ''
    // eslint-disable-next-line
    const emailRegex =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    const nameRegex = /^[a-zA-Z .]+$/

    if (!nameRegex.test(state.name)) {
      nameError = 'Name should contain only alphabets'
      isValidated = false
    }
    if (!state.name) {
      nameError = 'Name is required!'
      isValidated = false
    }
    if (state.name.length > 50) {
      nameError = 'Name should be less than 50 characters'
      isValidated = false
    }
    if (state.email.length > 100) {
      emailError = 'Email should be less than 100 characters'
      isValidated = false
    }
    if (!emailRegex.test(state.email.toLowerCase())) {
      emailError = 'Invalid email'
      isValidated = false
    }
    if (!state.email) {
      emailError = 'Email is required!'
      isValidated = false
    }
    if (!state.query) {
      queryError = 'Query message is required!'
      isValidated = false
    }
    if (state.query.length > 500) {
      queryError = 'Query message should be less than 500 characters'
      isValidated = false
    }

    setState({
      ...state,
      nameError,
      emailError,
      queryError,
    })

    return isValidated
  }

  const sendQuery = () => {
    if (state.sending) return

    if (!sent) {
      setState({
        ...state,
        sending: true,
      })
      if (!validate()) return

      const url = 'https://getmega.co/twirp/pb.Website/SendQueryEmail'
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          name: state.name,
          email: state.email,
          query: state.query,
        }),
      }

      fetch(url, options)
        .then(() => {
          setSent(true)
          setState({
            ...state,
            sending: false,
          })
        })
        .catch(err => {
          console.error(err)
          setState({
            ...state,
            apiError: 'Error sending email!',
          })
        })
    } else {
      setState(initializeState())
      setSent(false)
    }
  }

  return (
    <div id="ContactUs">
      <Container className="desktop-fix">
        <Row>
          <Col className="cu-desktop-header" md={4}>
            <div>
              <h1>Contact Us</h1>
              <p>To show us some love or share your feedback, reach us now.</p>
              <div className="d-none d-sm-flex">{renderSocial()}</div>
            </div>
            <div className="company d-none d-sm-block">
              <CompanyInfo />
            </div>
          </Col>

          <Col md={1} />

          <Col className="right-side">
            <section className="search-section">
              <div className={`search ${showLinks ? 'search-show-links' : ''}`}>
                <div
                  role="button"
                  tabIndex={0}
                  className="header"
                  onClick={() => {
                    setShowLinks(!showLinks)
                  }}
                  onKeyDown={() => {
                    setShowLinks(!showLinks)
                  }}
                >
                  <h2>Search our Help centre</h2>
                  <GatsbyImage
                    image={
                      data[showLinks ? 'up' : 'down'].childImageSharp
                        .gatsbyImageData
                    }
                    loading="eager"
                    alt=""
                    imgStyle={{ objectFit: 'contain' }}
                    className="head-icon"
                  />
                </div>
                {showLinks ? (
                  <div className="links">
                    {helpCenterCards.map(c => {
                      return (
                        <Link to={c.link} key={c.link}>
                          {c.title}
                        </Link>
                      )
                    })}
                  </div>
                ) : null}
              </div>
            </section>

            <section>
              {!sent ? (
                <div className="form">
                  <h2 className="form-title">Get in touch with us</h2>

                  <label htmlFor="fullname">
                    Full Name*
                    <input
                      type="text"
                      name="fullname"
                      placeholder="Full Name"
                      onChange={e => {
                        setState({
                          ...state,
                          name: e.currentTarget.value,
                        })
                      }}
                    />
                    <span
                      className={`error ${state.nameError ? '' : 'opacity0'}`}
                    >
                      {state.nameError}
                    </span>
                  </label>

                  <label htmlFor="email">
                    Email Address*
                    <input
                      type="text"
                      name="email"
                      placeholder="Email address"
                      onChange={e => {
                        setState({
                          ...state,
                          email: e.currentTarget.value,
                        })
                      }}
                    />
                    <span
                      className={`error ${state.emailError ? '' : 'opacity0'}`}
                    >
                      {state.emailError}
                    </span>
                  </label>

                  <label htmlFor="query" className="no-bottom-margin">
                    Type your query here*
                    <textarea
                      name="query"
                      placeholder="Your message"
                      className="no-bottom-margin"
                      onChange={e => {
                        setState({
                          ...state,
                          query: e.currentTarget.value,
                        })
                      }}
                    />
                    <span
                      className={`error ${state.queryError ? '' : 'opacity0'}`}
                    >
                      {state.queryError}
                    </span>
                  </label>

                  <div className="error top-spacing">
                    <span className={`${state.apiError ? 'O1' : 'O0'}`}>
                      {state.apiError}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="sent">
                  <p className="sent-text">Query Sent</p>
                  <p>Thank you for Getting in touch with us.</p>
                  <p className="no-bottom-margin">
                    We usually get back to you within 24 hours. Kindly be
                    patient. Meanwhile keep playing and have fun !
                  </p>
                </div>
              )}
            </section>
          </Col>
        </Row>

        <Row>
          <Col md={4} />
          <Col md={1} />
          <Col>
            <section>
              <div className="cta">
                <button
                  type="button"
                  className="send"
                  onClick={() => {
                    sendQuery()
                  }}
                  disabled={state.sending}
                >
                  {sent ? 'SUBMIT ANOTHER' : 'SEND'}
                </button>
                {/* <button type="button" className="chat">
                  CHAT WITH US
                </button> */}
              </div>
            </section>
          </Col>
        </Row>
      </Container>

      <Container>
        <section className="cu-mobile-footer">
          <div className="company">
            <CompanyInfo />
            {renderSocial()}
          </div>
        </section>
      </Container>
    </div>
  )
}

export default ContactUs
